<template>
  <th-wrapper
    :title="$t('pages.settings.analytics.delegated.title')"
    :subtitle="$t('pages.settings.analytics.delegated.explainer')"
  >
    <!-- External Sources -->
    <div class="-mb-6">
      <hr />

      <h4
        class="font-bold text-sm mt-8 mb-0"
        :class="{ 'color-main-text': isWhiteLabel }"
        v-text="$t(`pages.settings.analytics.delegated.external_sources.title`)"
      />

      <p
        class="text-sm mb-6 mt-2"
        :class="{ italic: !isWhiteLabel, 'mt-4 color-subtitle': isWhiteLabel }"
        v-text="
          $t('pages.settings.analytics.delegated.external_sources.exaplainer')
        "
      />

      <!-- Shall receiving data from sources be allowed in general? -->
      <div class="mb-6">
        <el-switch
          v-model="external_sources_enabled"
          :active-text="
            $t(
              'pages.settings.analytics.delegated.external_sources.form.enabled.label'
            )
          "
        >
          <el-input v-model="external_sources_enabled" />
        </el-switch>
      </div>

      <el-form
        v-for="(item, index) in external_sources"
        :key="index"
        :model="item"
      >
        <el-row :gutter="20">
          <!-- Tillhub Client ID -->
          <el-col :xl="6" :lg="6" :md="12">
            <el-form-item
              required
              :label="
                $t(
                  'pages.settings.analytics.delegated.external_sources.systems.form.id.label'
                )
              "
            >
              <el-input
                v-model="item.id"
                :placeholder="
                  $t(
                    'pages.settings.analytics.delegated.external_sources.systems.form.id.placeholder'
                  )
                "
                @input="(v) => handleInput(v, 'external_sources', index, 'id')"
              />
            </el-form-item>
          </el-col>

          <!-- Name -->
          <el-col :xl="4" :lg="4" :md="12">
            <el-form-item
              :label="
                $t(
                  'pages.settings.analytics.delegated.external_sources.systems.form.name.label'
                )
              "
            >
              <el-input
                v-model="item.name"
                :placeholder="
                  $t(
                    'pages.settings.analytics.delegated.external_sources.systems.form.name.placeholder'
                  )
                "
                @input="
                  (v) => handleInput(v, 'external_sources', index, 'name')
                "
              />
            </el-form-item>
          </el-col>

          <!-- Type -->
          <el-col :xl="4" :lg="4" :md="12">
            <el-form-item
              required
              :label="
                $t(
                  'pages.settings.analytics.delegated.external_sources.systems.form.type.label'
                )
              "
            >
              <el-select
                v-model="item.type"
                class="w-full"
                @change="
                  (v) => handleInput(v, 'external_sources', index, 'type')
                "
              >
                <el-option
                  label="tillhub-analytics-api"
                  value="tillhub-analytics-api"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <!-- Actions -->
          <el-col :xl="10" :lg="10" :md="12">
            <el-form-item
              :label="
                $t(
                  'pages.settings.analytics.delegated.external_sources.systems.form.enabled.label'
                )
              "
            >
              <div class="flex items-center">
                <!-- Enabled -->
                <el-switch
                  v-model="item.enabled"
                  @change="
                    (v) => handleInput(v, 'external_sources', index, 'enabled')
                  "
                >
                  <el-input
                    v-model="item.enabled"
                    @input="
                      (v) =>
                        handleInput(v, 'external_sources', index, 'enabled')
                    "
                  />
                </el-switch>

                <!-- Delete -->
                <el-button
                  plain
                  icon="Delete"
                  class="ml-4 el-button--text-icon"
                  @click="removeItem(item, index, 'external_sources')"
                />

                <!-- Add -->
                <el-button
                  v-if="index === external_sources.length - 1"
                  class="el-button--primary-icon"
                  plain
                  icon="Plus"
                  @click="addExternalSource"
                />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <!-- Add when empty -->
      <el-button
        v-if="external_sources.length === 0"
        plain
        icon="Plus"
        class="mb-6"
        :class="primaryIconType"
        @click="addExternalSource"
      >
        {{ $t('common.interactions.buttons.add') }}
      </el-button>
    </div>

    <!-- External targets -->
    <div class="-mb-6">
      <hr class="mt-8" />

      <h4
        class="font-bold text-sm mt-8 mb-0"
        :class="{ 'color-main-text': isWhiteLabel }"
        v-text="$t(`pages.settings.analytics.delegated.external_targets.title`)"
      />

      <p
        class="text-sm mb-6 mt-2"
        :class="{ italic: !isWhiteLabel, 'mt-4 color-subtitle': isWhiteLabel }"
        v-text="
          $t('pages.settings.analytics.delegated.external_targets.exaplainer')
        "
      />

      <!-- Allow sending data in general -->
      <div class="mb-6">
        <el-switch
          v-model="external_targets_enabled"
          :active-text="
            $t(
              'pages.settings.analytics.delegated.external_targets.form.enabled.label'
            )
          "
        >
          <el-input v-model="external_targets_enabled" />
        </el-switch>
      </div>

      <el-form
        v-for="(item, index) in external_targets"
        :key="index"
        :model="item"
      >
        <el-row :gutter="20">
          <!-- Tillhub Client ID -->
          <el-col :xl="6" :lg="6" :md="12">
            <el-form-item
              required
              :label="
                $t(
                  'pages.settings.analytics.delegated.external_targets.systems.form.id.label'
                )
              "
            >
              <el-input
                v-model="item.id"
                :placeholder="
                  $t(
                    'pages.settings.analytics.delegated.external_targets.systems.form.id.label'
                  )
                "
                @input="(v) => handleInput(v, 'external_targets', index, 'id')"
              />
            </el-form-item>
          </el-col>

          <!-- Type -->
          <el-col :xl="4" :lg="4" :md="12">
            <el-form-item
              required
              :label="
                $t(
                  'pages.settings.analytics.delegated.external_targets.systems.form.type.label'
                )
              "
            >
              <el-select
                v-model="item.type"
                class="w-full"
                @change="
                  (v) => handleInput(v, 'external_targets', index, 'type')
                "
              >
                <el-option
                  label="tillhub-analytics-api"
                  value="tillhub-analytics-api"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <!-- Actions -->
          <el-col :xl="10" :lg="10" :md="12">
            <el-form-item
              :label="
                $t(
                  'pages.settings.analytics.delegated.external_targets.systems.form.enabled.label'
                )
              "
            >
              <div class="flex items-center">
                <!-- Enable -->
                <el-switch
                  v-model="item.enabled"
                  @update:modelValue="
                    (v) => handleInput(v, 'external_targets', index, 'enabled')
                  "
                >
                  <el-input
                    v-model="item.enabled"
                    @input="
                      (v) =>
                        handleInput(v, 'external_targets', index, 'enabled')
                    "
                  />
                </el-switch>

                <!-- Delete -->
                <el-button
                  plain
                  icon="Delete"
                  class="ml-4 el-button--text-icon"
                  @click="removeItem(item, index, 'external_targets')"
                />

                <!-- Add -->
                <el-button
                  v-if="index === external_targets.length - 1"
                  class="el-button--primary-icon"
                  plain
                  icon="Plus"
                  @click="addExternalTarget"
                />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <!-- Add when empty -->
      <el-button
        v-if="external_targets.length === 0"
        plain
        icon="Plus"
        class="mb-6"
        :class="primaryIconType"
        @click="addExternalTarget"
      >
        {{ $t('common.interactions.buttons.add') }}
      </el-button>
    </div>
  </th-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'
import cloneDeep from 'clone-deep'
import { isUnifiedCommerce } from '@/constants'

export default {
  name: 'SettingsAnalyticsDelegated',
  data() {
    return {}
  },
  computed: {
    analyticsEnabled() {
      return (
        safeGet(
          this.$store.state.Config.clientAccountConfiguration,
          'analytics.enabled'
        ) || true
      )
    },
    external_sources_enabled: {
      get() {
        return (
          safeGet(
            this.$store.state.Config.clientAccountConfiguration,
            'analytics.externals.enabled'
          ) || false
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'analytics.externals.enabled',
          value: newValue
        })
      }
    },
    external_targets_enabled: {
      get() {
        return (
          safeGet(
            this.$store.state.Config.clientAccountConfiguration,
            'analytics.external_targets.enabled'
          ) || false
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'analytics.external_targets.enabled',
          value: newValue
        })
      }
    },
    external_sources: {
      get() {
        return (
          cloneDeep(
            safeGet(
              this.$store.state.Config.clientAccountConfiguration,
              'analytics.externals.systems'
            )
          ) || []
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'analytics.externals.systems',
          value: newValue
        })
      }
    },
    external_targets: {
      get() {
        return (
          cloneDeep(
            safeGet(
              this.$store.state.Config.clientAccountConfiguration,
              'analytics.external_targets.systems'
            )
          ) || []
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'analytics.external_targets.systems',
          value: newValue
        })
      }
    },

    primaryIconType() {
      return isUnifiedCommerce()
        ? 'el-button--primary'
        : 'el-button--primary-icon'
    },

    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },
  methods: {
    handleInput(newValue, itemsPath, index, path) {
      const items = cloneDeep(this[itemsPath])
      items[index] = {
        ...items[index],
        [path]: newValue
      }

      this[itemsPath] = items
    },
    addExternalTarget() {
      this.external_targets = [
        ...this.external_targets,
        {
          enabled: true,
          id: null,
          type: null
        }
      ]
    },
    addExternalSource() {
      this.external_sources = [
        ...this.external_sources,
        {
          enabled: true,
          name: null,
          id: null,
          type: null
        }
      ]
    },
    removeItem(item, index, targetListName) {
      this[targetListName] = this[targetListName].filter(
        (_, _index) => _index !== index
      )
    }
  }
}
</script>
