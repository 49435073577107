<template>
  <delegated />
</template>

<script>
import Delegated from './components/delegated'

export default {
  components: {
    Delegated
  },
  metaInfo() {
    return {
      title: this.$t('pages.settings.analytics.title')
    }
  }
}
</script>
